import React from "react";
import "./newtons-cradle.scss";

function NewtonsCradle(props) {
  const { cn } = props;
  return (
    <div className={`newtonsCradle ${cn}`}>
      <div className="frame">
        <div className="sphere"></div>
        <div className="sphere"></div>
        <div className="sphere"></div>
        <div className="sphere"></div>
        <div className="sphere"></div>
      </div>
      <div className="base"></div>
    </div>
  );
}

export default NewtonsCradle;
